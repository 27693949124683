<template>
  <div id="app">
    <Taipei width="800" height="600" stroke="#000000" fill="#DCDCDC" fit="false" lon="121.54" lat="25.09" scale="100000"></Taipei>
  </div>
</template>

<script>
import { Taipei } from 'taiwan-vue-components'

export default {
  name: 'App',
  components: {
    Taipei
  }
}
</script>
